<template>
  <page-layout>
    <span slot="header-name">{{ $tf('dossiers') }}</span>
    <span slot="header-detail">{{ $tfo('common.active,,3') }}: {{ dossiersParams.active }}, {{ $tfo('common.total') }}: {{ dossiersParams.total }}</span>

    <div slot="header-actions">
      <el-button
        name="create-btn"
        class="header-button"
        circle
        icon="el-icon-plus"
        size="small"
        @click="$router.push({ path: '/dossiers/create/' })"
        :disabled="$hasNoPermission('ffsecurity.add_dossier')"
      />

      <confirm-button
        :target="target"
        type="default"
        @confirm="deleteSelection"
        :disabled="!(selection && selection.length) || $hasNoPermission('ffsecurity.add_dossier')"
      >
      </confirm-button>

      <el-button name="monitoring-btn-add" v-if="$store.getters.puppeteer" @click="isSenderVisible = true" :disabled="!(selection && selection.length)">
        {{ $tf('add_to_monitoring') }}
      </el-button>

      <el-button
        name="monitoring-btn-remove"
        v-if="$store.getters.puppeteer"
        @click="() => removeFromMonitoring(selectionIDs)"
        :disabled="!(selection && selection.length)"
        >{{ $tf('remove_from_monitoring') }}
      </el-button>
    </div>
    <table-navigation :state="state" slot="header-content-navigation"></table-navigation>

    <el-table
      slot="content"
      name="dossiers-table"
      stripe
      ref="table"
      :size="$vars.sizes.table || $vars.sizes.common"
      :data="state.items"
      v-loading="state.loading"
      class="dossiers-table"
      @selection-change="selectionChange"
      @row-click="rowClick"
      @sort-change="sortChangeHandler"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column sortable="custom" prop="id" :label="$tf('common.id')" width="60"></el-table-column>
      <el-table-column prop="" :label="$tf('common.photo')" width="176">
        <template slot-scope="{ row }">
          <objects-inline :id="row.id" :limit="1"></objects-inline>
        </template>
      </el-table-column>
      <el-table-column prop="name" :label="$tf('common.dossier')">
        <template slot-scope="{ row }">
          <router-link name="item-btn" :to="{ path: getItemLink(row) }">{{ row.name }}</router-link>
          <br />
          {{ row.comment }}
          <dossier-lists-inline :ids="row.dossier_lists"></dossier-lists-inline>
          <div class="dossier__license-plate-number" v-if="$store.getters.hasEnabledObjects(ObjectsType.Cars) && row.car_license_plate_number">
            {{ $tf('license_plate_number') }}: {{ row.car_license_plate_number }}
          </div>
          <div v-if="row.person_id" class="mar-v-1" @click.stop>
            {{ $tf('person') }} <router-link :to="getPersonEventsUrl(row)">{{ row.person_id }}</router-link
            >, <router-link :to="getContactsUrl(row)"><i class="fa fa-handshake-o" /></router-link>
          </div>
          <div>
            <div class="meta-field" :key="item.name" v-for="item in metaFields">{{ item.label || item.name }}: {{ getMetaValue(item, row) }}</div>
          </div>
          <dossier-monitoring-item
            ref="dossierMonitorings"
            :key="'m-' + row.id"
            v-if="$store.getters.puppeteer"
            :dossier="row.id"
            :add="false"
            class="mar-v-1"
            @remove="removeFromMonitoring([row.id])"
          />
        </template>
      </el-table-column>
      <el-table-column prop="enabled" :label="$tf('common.active,,2')" width="100" align="center">
        <template slot-scope="{ row }">
          <div @click.stop class="text-center">
            <el-checkbox v-model="row.active" @change="(v) => activeChange(v, row)"> </el-checkbox>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="role" :label="$tf('common.was_updated,, 1')" width="160">
        <template slot-scope="{ row }">
          {{ row.modified_date | isoStringToDate | formatDate }}
          <br />
          {{ row.modified_date | isoStringToDate | formatTime }}
        </template>
      </el-table-column>
    </el-table>

    <table-navigation :state="state" slot="footer-content-navigation"></table-navigation>

    <el-dialog :visible.sync="isSenderVisible" :title="$tf('add_to_monitoring')" slot="content">
      <puppeteer-sender v-if="isSenderVisible" type="monitoring" :dossierIDs="selectionIDs" @sent="puppeteerSentHandler" />
    </el-dialog>
  </page-layout>
</template>

<script>
import TableNavigation from '@/components/tables/navigation.vue';
import ObjectsInline from '../objects/inline.vue';
import DossierListsInline from '../dossier-lists/inline.vue';
import ObjectThumbnail from '../objects/thumbnail.vue';
import PuppeteerSender from '../puppeteer/sender';
import DossierMonitoringItem from './monitoring.item';
import ConfirmButton from '../confirm/confirm-button';
import qs from 'qs';
import _ from '@/apps/common/lodash';
import { ObjectsType } from '@/store/objects/get.module';
import PageLayout from '@/components/page/layout';

function alphabeticalSort(a, b) {
  return a.localeCompare(b);
}

const SortMetaNameIndex = {
  name: 1,
  short_description: 2,
  full_description: 3
};

export default {
  components: {
    PageLayout,
    DossierMonitoringItem,
    ObjectThumbnail,
    DossierListsInline,
    ObjectsInline,
    TableNavigation,
    PuppeteerSender,
    ConfirmButton
  },
  name: 'page-dossiers',
  data: () => ({
    selection: [],
    isSenderVisible: false,
    ObjectsType
  }),
  computed: {
    objects() {
      return this.$store.getters.enabledObjects;
    },
    filter() {
      return this.$store.state.dossiers.filter.current;
    },
    state() {
      return this.$store.state.dossiers;
    },
    dossiersParams() {
      return this.$store.state.stats.dossiers;
    },
    selectionIDs() {
      return this.selection.map((d) => d.id);
    },
    uploadHeaders() {
      return {
        Method: 'POST',
        Authorize: 'Token ' + this.$store.state.token,
        Accept: 'application/json',
        ContentType: 'application/json',
        data: {}
      };
    },
    hasMetaFields() {
      return !!this.$store.state.config.dossier_meta;
    },
    metaFields() {
      let dossierConfig = this.$store.state.config.dossier_meta;
      return ((dossierConfig && dossierConfig.items) || []).filter((v) => v.display.indexOf('list') > -1);
    },
    target() {
      return this.selection.length > 1 ? 'dossiers' : 'dossier';
    }
  },
  watch: {
    'filter.ordering': function (v, p) {
      this.applySort();
    },
    filter: {
      handler() {
        window && window.scrollTo(0, 0);
      },
      deep: true
    }
  },
  mounted() {
    this.$store.dispatch('getStats', 'dossiers');
    if (this.hasMetaFields && !this.$store.state.dicts.loaded_date) this.$store.dispatch(this.$store.state.dicts.Action.Get);
    this.$nextTick(this.applySort.bind(this));
  },
  methods: {
    getDictItemLabel(id, dictName, field = 'id') {
      const dict = this.$store.state.dicts.items[dictName],
        item = dict && dict.find((v) => v[field] === id);
      return item ? item.label || item.name : dict ? this.$tf('item | not_found') : this.$tf('dictionary | not_found');
    },
    hasValue(v) {
      return v !== null && v !== undefined;
    },
    getMetaValue(metaField, item) {
      let r = '',
        metaValue = item.meta && item.meta[metaField.name];

      switch (metaField.type) {
        case 'list':
          if (this.hasValue(metaValue)) {
            r = metaField.multiple
              ? metaValue.map((v) => this.getDictItemLabel(v, metaField.items, metaField.custom_id)).join(', ')
              : this.getDictItemLabel(metaValue, metaField.items, metaField.custom_id);
          } else {
            r = this.$tf('not_set');
          }
          break;
        case 'datetime':
          r = metaValue ? this.$filters.formatDateTime(new Date(metaValue)) : this.$tf('not_set');
          break;
        case 'date':
          r = metaValue ? this.$filters.formatDate(new Date(metaValue)) : this.$tf('not_set');
          break;
        case 'boolean':
          r = metaValue === true ? this.$tf('enabled') : metaValue === false ? this.$tf('disabled') : this.$tf('not_set');
          break;
        default:
          r = Array.isArray(metaValue) ? metaValue.join(', ') : metaValue; // type = string, valuelist
          break;
      }

      return r;
    },
    sortMetaName(a, b) {
      return (SortMetaNameIndex[a] || 100) - (SortMetaNameIndex[b] || 100);
    },
    rowClick(item, column) {
      if (this.$store.state.app.key.ctrl || column.type === 'selection') return;
      this.$router.push({ path: this.getItemLink(item) });
    },
    selectionChange(v) {
      this.selection = v;
    },
    deleteSelection(v) {
      Promise.all(
        this.selection.map((v) => {
          return this.$store.dispatch(this.state.Action.Delete, { id: v.id });
        })
      )
        .then((v) => {
          this.$notify({ type: 'success', message: this.$tf(['common.action', 'common.success']) });
          this.$store.dispatch(this.state.Action.Get);
          this.$store.dispatch('getStats');
        })
        .catch((e) => {
          this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
          this.$store.dispatch(this.state.Action.Get);
        });
    },
    getItemLink(item) {
      return '/dossiers/' + encodeURIComponent(item.id) + '/';
    },
    getPersonEventsUrl(v) {
      let filter = Object.assign({}, this.$store.state.person_events.filter.empty, { person_in: v.person_id }),
        cleanFilter = _.pickBy(filter, (v) => !!v);
      return '/person-events/filter/' + qs.stringify(cleanFilter, { sort: alphabeticalSort });
    },
    getContactsUrl(v) {
      return `/contacts/filter/limit=10&matched_dossier=${v.id}&ordering=-id`;
    },
    activeChange(v, item) {
      this.$store
        .dispatch(this.state.Action.Update, { id: item.id, active: v })
        .then((v) => {
          this.$store.dispatch('getStats');
        })
        .catch((e) => {
          item.active = !v;
        });
      return false;
    },
    applySort() {
      const order = this.filter.ordering === 'id' ? 'ascending' : 'descending';
      this.$refs.table && this.$refs.table.sort('id', order);
    },
    sortChangeHandler(v) {
      const ordering = { ascending: 'id', descending: '-id' };
      this.filter.ordering = ordering[v && v.order] || null;
    },
    async removeFromMonitoring(ids) {
      await this.$store.dispatch('removeFromMonitoring', { dossierIDs: ids, vueContext: this });
      this.state.items = [];
      await this.$store.dispatch(this.state.Action.Get);
    },
    async puppeteerSentHandler(allPromises) {
      this.isSenderVisible = false;
      this.state.items = [];
      await this.$store.dispatch(this.state.Action.Get);
    }
  }
};
</script>

<style type="stylus">
.dossier__license-plate-number {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.dossier-content--main {
  font-weight: bold;
}

.meta-field {
  margin-top: 0.5rem;
}

.meta-field:first-child {
  margin-top: 1rem;
}
</style>
