<template>
  <div v-loading="loading">
    <div v-if="loaded">
      <div v-if="monitoringItem">
        <div class="mar-bottom--0-5">{{ $tf('added_to_monitoring') }}: {{ monitoringItem.created_date | isoStringToDate | formatDateTime }}</div>
        <el-button size="small" @click.stop="$emit('remove')">{{ $tf('remove_from_monitoring') }}</el-button>
        <el-button size="small" @click.stop="viewHandler">{{ $tf('view | events,,1') }}</el-button
        ><br />
      </div>
      <div v-else-if="add">
        <el-button size="small" @click.stop="$emit('add')" :disabled="false">{{ $tf('add_to_monitoring') }}</el-button>
      </div>
    </div>
    <div v-else>
      {{ $tf('loading') }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'dossier-monitoring-item',
  props: {
    dossier: {
      type: Number,
      required: true
    },
    add: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return { items: null, loading: false, loaded: false };
  },
  computed: {
    monitoringItem() {
      return this.items && this.items[0];
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.loading = true;
      return this.$store
        .dispatch(this.$store.state.ActionNames.PuppeteerRemoteMonitoring.Get, { filter: { dossier: this.dossier } })
        .then((v) => {
          this.items = v.results && v.results.filter((v) => !v.is_deleted);
        })
        .finally(() => {
          this.loading = false;
          this.loaded = true;
        });
    },
    viewHandler() {
      this.$router.push({ path: `/puppeteer/remote-monitoring/events/filter/dossier=${this.dossier}&limit=100` });
    }
  }
};
</script>
